import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TermosComponent } from './termos.component';

@NgModule({
    declarations: [TermosComponent],
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: 'termos',
                component: TermosComponent,
            },
        ]),
    ],
})
export class TermosModule {}
