<div class="class-title" class="p-5 text-center">
    <h1>
        <div class="class-title"><strong
            >AVISO EXTERNO DE PRIVACIDADE PARA CLIENTES E USUÁRIOS DA
            PLATAFORMA, SITE E APLICATIVO KIT ESCOLAR DUEPAY</strong
        >
        </div>
    </h1>

    <section>
        A PERSONAL NET TECNOLOGIA DE INFORMACAO LTDA., pessoa jurídica de
        direito privado, inscrita no CNPJ(MF), sob nº 09.687.900/0001-23, com
        sede à Rua Deodoro, nº 181, 4º andar, Sala 402, Centro, Florianópolis/SC
        - CEP: 88010-020, (“KIT ESCOLAR DUEPAY” e/ou “nós”), é uma empresa
        comprometida com a conscientização dos usuários do site
        <a>https://kitescolar.personalcard.com.br/</a> e do aplicativo “KIT
        ESCOLAR DUEPAY” (“Usuários”, “Site” e “Aplicativo”, respectivamente) e
        dos clientes que contratam os produtos e/ou serviços ofertados no Site
        ou no Aplicativo (“Clientes”, sendo os Clientes e Usuários referidos
        aqui em conjunto como “Titulares” ou “vocês”) sobre os dados pessoais
        tratados pela KIT ESCOLAR DUEPAY. Por isso, apresentamos nosso Aviso
        Externo de Privacidade (“Aviso de Privacidade”), que tem por objetivo
        fornecer informações claras e precisas aos Titulares acerca do
        tratamento de dados pessoais realizado pela KIT ESCOLAR DUEPAY. O
        presente documento visa apresentar de forma objetiva as características
        e hipóteses de tratamento dos dados pessoais dos Usuários e/ou Clientes
        da Plataforma KIT ESCOLAR DUEPAY, seja no ambiente do site ou do
        aplicativo.
    </section>
    <section>
        <div class="class-title"><strong
            >CASO VOCÊ NÃO CONCORDE COM QUAISQUER DAS DISPOSIÇÕES DESTE AVISO
            EXTERNO DE PRIVACIDADE, POR FAVOR NÃO UTILIZE NOSSO SITE OU NOSSO
            APLICATIVO, NEM CONTRATE OS PRODUTOS E/OU SERVIÇOS OFERTADOS PELA
            KIT ESCOLAR DUEPAY.</strong
        >
        </div>
    </section>
    <section>
        As palavras, expressões e abreviações com as letras iniciais maiúsculas
        não definidas neste Aviso Externo de Privacidade terão o significado
        atribuído a elas nos Termos e Condições de Uso da Plataforma.
    </section>
    <div class="class-title"><strong>
      DO CONTROLADOR DOS DADOS PESSOAIS DOS USUÁRIOS E/OU CLIENTES DA
      PLATAFORMA KIT ESCOLAR DUEPAY
  </strong><br></div>
    <section>
       
        A PERSONAL NET TECNOLOGIA DE INFORMACAO LTDA., pessoa jurídica de
        direito privado, inscrita no CNPJ(MF), sob nº 09.687.900/0001-23, com
        sede à Rua Deodoro, nº 181, 4º andar, Sala 402, Centro, Florianópolis/SC
        - CEP: 88010-020 (KIT ESCOLAR DUEPAY), é a empresa controladora dos
        dados pessoais tratados conforme as informações contidas neste Aviso
        Externo de Privacidade Para fins da legislação aplicável, “controlador”
        é a quem competem as decisões referentes ao tratamento de dados
        pessoais. Além disso, conforme será detalhado no presente documento, os
        seus dados pessoais poderão ser compartilhados com empresas do mesmo
        grupo econômico da KIT ESCOLAR DUEPAY e/ou empresas parceiras da KIT
        ESCOLAR DUEPAY para finalidades específicas e previamente informadas
        neste Aviso Externo de Privacidade, hipóteses em que tais empresas
        também poderão ser controladoras P á g i n a 2 | 7 autônomas dos seus
        dados pessoais. Lembre-se que, a qualquer momento você poderá consultar
        sobre as empresas e instituições com as quais estão sendo compartilhados
        seus dados pessoais.
    </section>
    <div class="class-title"><strong>QUAIS SÃO OS DADOS PESSOAIS TRATADOS</strong><br></div>
    <section>
        A KIT ESCOLAR DUEPAY trata dados pessoais dos Titulares que são
        coletados: (a) durante o acesso e/ou navegação do Site e/ou do
        Aplicativo; e (b) antes, durante ou após a contratação dos produtos e/ou
        prestação dos serviços pela KIT ESCOLAR DUEPAY sempre dentro dos limites
        da legislação aplicável. Especificamente, coletamos as seguintes
        informações dos Usuários e/ou Clientes, conforme o caso: • Informações
        cadastrais: Ao criar sua conta no Site ou no Aplicativo, o Titular nos
        fornecerá os seguintes dados pessoais (conforme aplicável): nome
        completo, números ou imagens de documentos de identificação (tais como
        CNH, RG ou CPF), endereço residencial, número de telefone, endereço de
        e-mail, entre outros dados e/ou imagens/fotos, também na modalidade
        selfie, de identificação ou necessários para o cadastro junto à KIT
        ESCOLAR DUEPAY. • Informações financeiras: Além dos dados cadastrais,
        nós poderemos coletar dados bancários no âmbito da contratação e/ou
        usufruto dos produtos e/ou serviços (tais como, identificação da
        instituição financeira, número da agência, número da conta de pagamento
        ou conta corrente, adimplência ou inadimplência, entre outros). •
        Informações transacionais: Nós poderemos coletar dados transacionais em
        conexão com nossos produtos e/ou serviços, tais como informações sobre
        movimentações financeiras e características de suas despesas. •
        Informações de navegação e uso: Nós poderemos coletar dados quando o
        Usuário utiliza o Site e/ou Aplicativo, tais como: geolocalização,
        páginas visitadas, anúncios visualizados, entre outros. O Usuário pode
        optar por não fornecer algumas informações, desde que não sejam
        necessárias para a utilização do Site e/ou do Aplicativo, mas é provável
        que a sua experiência com o Site ou com o Aplicativo não conte com todos
        os recursos oferecidos. • Registros de acesso e demais informações
        automatizadas: Nós poderemos coletar e armazenar algumas informações que
        recebe automaticamente toda vez que o Usuário interage com o Site e/ou
        com o Aplicativo, tais como: Internet Protocol (IP), tipo de navegador,
        bem como datas e horas das interações do Usuário com tais plataformas,
        em conformidade com o Marco Civil da Internet (MCI) e/ou qualquer outra
        legislação aplicável. • Informações coletadas através de cookies: Nós
        poderemos coletar e armazenar algumas informações através cookies e/ou
        tecnologias similares (como pixels e tags), caso você habilite tais
        ferramentas. Para mais informações sobre este tema, consulte a seção
        “Cookies e Tecnologias Similares” abaixo. • Informações de Serviços de
        Atendimento e Ouvidoria: Podemos coletar informações que o Titular
        voluntariamente nos fornece ao interagir com nosso Serviço de
        Atendimento ao Cliente e de Ouvidoria. P á g i n a 3 | 7 • Outras
        fontes: Por fim, a KIT ESCOLAR DUEPAY poderá receber e armazenar outras
        informações a seu respeito, tais como as informações mantidas por
        entidades de apoio ao crédito, para fins de evitar fraudes dentro dos
        limites estabelecidos na legislação. Cookies e Tecnologias Similares O
        Site e/ou o Aplicativo poderão utilizar instruções eletrônicas,
        conhecidas como cookies e/ou tecnologias similares, como pixels e tags,
        que serão enviadas ao browser e armazenadas no disco do seu computador.
        Alguns cookies são necessários para o funcionamento do Site e do
        Aplicativo. Esses cookies geralmente, são definidos em resposta a ações
        feitas pelos Usuários, tais como definir preferências de privacidade,
        fazer login ou preencher formulários. Outra finalidade dos cookies é
        verificar quem são os Usuários do Site e/ou do Aplicativo, para,
        eventualmente, facilitar sua navegação com as características,
        preferências e qualidade das informações veiculadas por seus
        cadastrados. Esses dados são usados para nos certificarmos de que
        estamos enviando a você as informações que deseja receber e ler. Ainda
        assim, você pode gerenciar as configurações dos cookies na configuração
        de seu navegador ou dispositivo a qualquer hora. Ao desabilitar todos os
        cookies nas configurações de seu navegador, é possível que certas seções
        ou recursos do Site e/ou do Aplicativo não funcionem ou ocasionem
        demasiada demora para carregar os conteúdos, pois seu navegador pode nos
        impedir de definir cookies necessários. Também é possível que você
        precise digitar seu login e senha múltiplas vezes, ao ativar essa opção.
    </section>
    <div class="class-title"><strong>PARA QUAIS FINALIDADES OS DADOS SÃO TRATADOS</strong><br></div>
    <section>
      Os dados pessoais dos Usuários e/ou Clientes, conforme o caso, são tratados pela
        KIT ESCOLAR DUEPAY para as seguintes finalidades: • Disponibilizar aos
        Usuários um ambiente eletrônico por meio do qual os Usuários podem obter
        mais informações sobre os serviços da KIT ESCOLAR DUEPAY, além de obter
        informações detalhadas sobre a KIT ESCOLAR DUEPAY; • Atuar como
        correspondente bancário na contratação de produtos financeiros ofertados
        por instituições financeiras; • Viabilizar a abertura e movimentação da
        conta de pagamento do Usuário junto à KIT ESCOLAR DUEPAY; • Viabilizar a
        emissão de cartões físicos e virtuais; • Viabilizar a liberação de
        crédito; • Viabilizar a contratação de outros produtos e/ou serviços
        ofertados pela KIT ESCOLAR DUEPAY por meio do Site ou do Aplicativo; •
        Cumprir obrigações legais ou regulatórias aplicáveis às instituições de
        pagamento. Tais atividades incluem, mas não se limitam a análise de
        fraudes, procedimento específico para cadastro, procedimento de “conheça
        seu cliente” (know your client), P á g i n a 4 | 7 investigação e
        reporte de operações com indícios de lavagem de dinheiro e/ou
        financiamento ao terrorismo, dentre outras definidas pela legislação.
        (conforme aplicável ao Titular); • Prestar e aprimorar o serviço de
        atendimento aos Titulares, o que pode envolver diversas atividades
        relacionadas aos serviços contratados. Tais atividades incluem, mas não
        se limitam, ao cadastro de nova senha no Site e/ou no Aplicativo,
        processamento de reclamações, sugestões ou dúvidas sobre serviços etc.;
        • Entrar em contato com os Titulares para enviar informações sobre: (a)
        produtos e/ou serviços oferecidos pela KIT ESCOLAR DUEPAY e seus
        parceiros, bem como demais informações relevantes sobre a KIT ESCOLAR
        DUEPAY, (b) alterações relevantes nas funcionalidades do Site e do
        Aplicativo, (c) outros assuntos que a KIT ESCOLAR DUEPAY julgue que
        possam interessar os Titulares; • Aprimorar os serviços e/ou
        funcionalidades do Site e/ou do Aplicativo; • Entrar em contato com o
        Cliente para informar sobre o processamento de seus pedidos e
        solicitações, assim como para informar-lhe sobre ofertas, programas,
        sorteios ou promoções da KIT ESCOLAR DUEPAY ou de seus parceiros
        comerciais; • Desenvolver estudos internos sobre os interesses,
        comportamentos e demografia dos Titulares para compreender melhor suas
        preferências pessoais e assim aprimorar e customizar a sua experiência
        no uso do Site e do Aplicativo; • Aprimorar as iniciativas comerciais e
        promocionais da KIT ESCOLAR DUEPAY, bem como melhorar nossa oferta de
        serviços personalizados que possam interessar os Titulares; e •
        Classificar e agrupar informações de Titulares, assim como consolidar
        dados estatísticos a respeito desses, inclusive para definir a
        composição da base de Usuários do Site e do Aplicativo e o volume de
        tráfego em nossos servidores.
    </section>
    <div class="class-title"><strong>COM QUEM OS DADOS PESSOAIS SÃO COMPARTILHADOS</strong><br></div>
    <section>
      A KIT ESCOLAR DUEPAY poderá compartilhar os dados pessoais de clientes e/ou
        usuários com terceiros ou parceiros de negócios que sejam relevantes
        para fins de viabilizar a utilização da Plataforma pelos Usuários ou
        viabilizar a contratação dos produtos e/ou a prestação dos serviços ao
        Cliente. Referido compartilhamento ocorre com base nos seguintes
        critérios e para as finalidades descritas abaixo. • Instituições
        financeiras parceiras: A KIT ESCOLAR DUEPAY poderá compartilhar seus
        dados pessoais com instituições financeiras, com as quais possui acordo
        operacional, para fins de viabilizar os serviços prestados aos Clientes.
        • Prestadores de serviços ou parceiros comerciais: Podemos compartilhar
        seus dados pessoais com prestadores de serviços ou parceiros comerciais
        contratados pela KIT ESCOLAR DUEPAY para fins de: (a) fornecimento de
        software, sistemas de antifraude e/ou ferramenta para gestão de
        Clientes, (b) desenvolvimento, manutenção e aperfeiçoamento dos recursos
        e funcionalidades dos serviços e sistemas dos nossos parceiros, e (c)
        gestão e aprimoramento do Site e do Aplicativo. Tais agentes recebem
        seus dados pessoais com a finalidade específica de prestar serviços à
        KIT ESCOLAR DUEPAY e não possuem quaisquer direitos de uso de seus dados
        fora dessa hipótese. P á g i n a 5 | 7 • Entidades de Proteção ao
        Crédito: Podemos enviar determinadas informações do Cliente a entidades
        dedicadas a reduzir o risco de crédito e proteger empresas e indivíduos
        contra fraudes. Nesse sentido, o compartilhamento de dados pessoais
        poderá ocorrer para fins de validação dos dados biométricos, por meio de
        score biométrico e/ou de score cadastral. • Requisição de autoridade
        competente: A KIT ESCOLAR DUEPAY ainda poderá compartilhar seus dados
        pessoais com terceiros (incluindo órgãos governamentais) a fim de
        responder a investigações, medidas judiciais, processos judiciais ou
        investigar, impedir ou adotar medidas acerca de atividades ilegais,
        suspeita de fraude ou situações que envolvam ameaças em potencial à
        segurança física de qualquer pessoa ou se de outra maneira exigido pela
        legislação. • Novos negócios: Se a KIT ESCOLAR DUEPAY estiver envolvida
        em uma fusão, aquisição ou venda de todos ou de parte de seus ativos,
        seus dados pessoais poderão ser transferidos para a empresa ou pessoa
        adquirente. Em circunstâncias nas quais a identidade do controlador de
        seus dados pessoais se alterar como resultado de qualquer transação,
        você será notificado de quaisquer escolhas que possa ter acerca de seus
        dados pessoais.
    </section>
    <div class="class-title"><strong>TRANSFERÊNCIAS DOS DADOS PESSOAIS PARA FORA DO BRASIL</strong><br></div>
    <section>
      A KIT ESCOLAR DUEPAY poderá transferir alguns os dados pessoais de
        clientes e/ou usuários a prestadores de serviços localizados no
        exterior, incluindo prestadores de serviços em nuvem. Quando os dados
        pessoais de clientes e/ou usuários forem transferidos para fora do
        Brasil pela KIT ESCOLAR DUEPAY, a KIT ESCOLAR DUEPAY adotará medidas
        apropriadas para garantir a proteção adequada de seus dados pessoais em
        conformidade com os requisitos da legislação aplicável de proteção de
        dados, incluindo a celebração de contratos apropriados de transferência
        internacional de dados pessoais.
    </section>
    <div class="class-title"><strong
        >POR QUANTO TEMPO OS DADOS PESSOAIS SÃO RETIDOS PELA KIT ESCOLAR
        DUEPAY</strong><br></div>
    <section>
        Armazenamos e mantemos suas informações: (i) pelo tempo exigido por
        lei; (ii) até o término do tratamento de dados pessoais, conforme
        mencionado abaixo; ou (iii) pelo tempo necessário a preservar o legítimo
        interesse e a possibilidade de exercer direitos regulares da KIT ESCOLAR
        DUEPAY. Assim, trataremos seus dados, por exemplo, durante os prazos
        prescricionais aplicáveis ou enquanto necessário para cumprimento de
        obrigação legal ou regulatória. O término do tratamento de dados
        pessoais ocorrerá nos seguintes casos: • Quando a finalidade pela qual
        os dados pessoais do Titular foram coletados for alcançada e/ou os dados
        pessoais coletados deixarem de ser necessários ou pertinentes ao alcance
        de tal finalidade; P á g i n a 6 | 7 • Quando o Titular estiver em seu
        direito de solicitar o término do tratamento e a exclusão de seus dados
        pessoais e o fizer; e • Quando houver uma determinação legal neste
        sentido. Nesses casos de término de tratamento de dados pessoais,
        ressalvadas as hipóteses estabelecidas pela legislação aplicável ou pelo
        presente Aviso Externo de Privacidade, os dados pessoais serão
        descartados pela KIT ESCOLAR DUEPAY.
    </section>

    <div class="class-title"><strong
        >DIREITOS DOS CLIENTES E USUÁRIOS DA PLATAFORMA KIT ESCOLAR DUEPAY
        EM RELAÇÃO AOS DADOS PESSOAIS TRATADOS </strong><br></div>
    <section>
        Você terá determinados direitos em relação aos seus dados pessoais.
        Tais direitos incluem, mas não se limitam a: • Solicitar o acesso a seus
        dados pessoais e/ou a confirmação da existência de tratamento de dados;
        • Receber informações claras e completas sobre o tratamento de seus
        dados pessoais, incluindo sobre as hipóteses de compartilhamento de
        dados pessoais; • Solicitar que retifiquemos quaisquer dados pessoais
        imprecisos, incompletos e desatualizados; • Se opor às atividades de
        tratamento, solicitar a anonimização, eliminação ou bloqueio de seus
        dados pessoais, em circunstâncias específicas; • Solicitar a
        portabilidade de seus dados pessoais; • Revogar o consentimento a
        qualquer momento, quando a KIT ESCOLAR DUEPAY tratar seus dados pessoais
        com base no consentimento; e • Solicitar a revisão de decisões
        automatizadas que possam afetar seus interesses. • Exercer reclamações
        perante a Autoridade Nacional de Proteção de Dados Pessoais (ANPD). Em
        caso de dúvidas sobre a forma como tratamos seus dados pessoais ou para
        o exercício dos direitos acima descritos, você poderá entrar em contato
        com nossa ouvidoria pelo e-mail <a>ouvidoria@personalcard.com.br</a> ou
        através do Aplicativo da KIT ESCOLAR DUEPAY, conforme aplicável.
    </section>
    <div class="class-title"><strong>DA PROTEÇÃO DOS DADOS PESSOAIS</strong><br></div>
    <section>
      A KIT ESCOLAR DUEPAY
        utiliza medidas técnicas e organizacionais apropriadas para proteger
        seus dados pessoais contra tratamento desautorizado ou ilegal e contra
        perda acidental, destruição ou danos. Seus dados pessoais são
        armazenados de maneira segura em equipamentos protegidos. Apenas um
        número limitado de pessoas terá acesso a tais equipamentos e apenas
        indivíduos com motivos legítimos terão acesso a seus dados pessoais.
    </section>
    <div class="class-title"><strong>ALTERAÇÕES DESTE AVISO DE EXTERNO DE PRIVACIDADE</strong><br></div>
    <section>
        A KIT
        ESCOLAR DUEPAY reserva-se o direito de alterar este Aviso Externo de
        Privacidade a qualquer momento, mediante publicação da versão atualizada
        no site oficial da KIT ESCOLAR DUEPAY. Em caso de alterações relevantes
        neste Aviso Externo de Privacidade, o Titular receberá um aviso a esse
        respeito.
    </section>
    <div class="class-title"><strong>INTERAÇÕES COM SITES DE TERCEIROS</strong><br></div>
    <section>
        Podemos disponibilizar
        links para outros sites na Internet. A KIT ESCOLAR DUEPAY NÃO SE
        RESPONSABILIZA POR ESTES SITES E CONTEÚDOS E, AINDA, NÃO COMPARTILHA,
        SUBSCREVE, MONITORA, VALIDA OU ACEITA A FORMA COMO ESSES SITES OU
        FERRAMENTAS DE ARMAZENAMENTO DE CONTEÚDO COLETAM, PROCESSAM E TRANSFEREM
        SUAS INFORMAÇÕES PESSOAIS. Recomendamos que o Titular consulte as
        respectivas políticas de privacidade de tais sites para se informar
        adequadamente a respeito do uso de suas informações pessoais por outros
        sites ou outras ferramentas.
    </section>
    <div class="class-title"><strong>COMUNICAÇÕES</strong><br></div>
    <section>
        A KIT ESCOLAR DUEPAY poderá contatá-lo por
        qualquer meio, inclusive e-mail, telefone, SMS, aplicativo WhatsApp e
        correspondência, para o envio de comunicações sobre a sua Conta.
    </section>
    <div class="class-title"><strong>Dúvidas sobre este Aviso Externo de Privacidade </strong><br></div>
    <section>
        Se você
        tiver qualquer dúvida em relação a este Aviso Externo de Privacidade ou
        a respeito da forma como a KIT ESCOLAR DUEPAY trata seus dados pessoais,
        você deverá entrar em contato com nossa Ouvidoria pelo e-mail:
        <a>ouvidoria@personalcard.com.br</a>
    </section>
</div>
